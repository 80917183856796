<template>
  <b-row v-if="question">
    <b-col>
      <div class="d-flex font-weight-bolder pb-2">
        <div>
          <span>Câu hỏi {{ question.order }}:</span>
          <span class="ml-2">{{ question.name }}</span>
        </div>
        <action-dropdown
          v-if="showAction"
          :value="question"
          :show_copy="false"
          :show_view="false"
          :show_edit="false"
          :show_delete="false"
          class="dropdown-question"
        >
          <template>
            <template v-if="isDetail">
              <b-dropdown-text tag="div" class="navi-item cursor-pointer">
                <a class="navi-link text-primary" @click.stop="viewQuestion">
                  <span class="menu-icon svg-icon svg-icon-sm">
                    <inline-svg
                      class="svg-icon"
                      src="/media/svg/icons/Neolex/Basic/eye.svg"
                    />
                  </span>
                  <span class="navi-text text-primary"
                    >Xem chi tiết câu hỏi</span
                  >
                </a>
              </b-dropdown-text>
            </template>
            <template v-else>
              <b-dropdown-text
                tag="div"
                class="navi-item cursor-pointer"
                @click="editQuestion"
              >
                <a class="navi-link text-primary">
                  <span class="menu-icon svg-icon svg-icon-sm">
                    <inline-svg
                      class="svg-icon"
                      src="/media/svg/icons/Neolex/Basic/edit-2.svg"
                    />
                  </span>
                  <span class="navi-text text-primary">Chỉnh sửa câu hỏi</span>
                </a>
              </b-dropdown-text>
              <b-dropdown-divider v-if="!question.source" />
              <b-dropdown-text
                tag="div"
                class="navi-item cursor-pointer"
                @click="deleteQuestion"
              >
                <a class="navi-link text-danger">
                  <span class="menu-icon svg-icon svg-icon-sm">
                    <inline-svg
                      class="svg-icon"
                      src="/media/svg/icons/Neolex/Basic/trash-2.svg"
                    />
                  </span>
                  <span class="navi-text text-danger">Xóa câu hỏi</span>
                </a>
              </b-dropdown-text>
            </template>
          </template>
        </action-dropdown>
      </div>
      <template v-if="isSingle">
        <b-form-radio-group
          v-model="selected"
          :id="`radio-group-${id}`"
          :name="`radio-group-${id}`"
          :options="answers"
          size="lg"
          class="answer-radio ml-10"
          stacked
          disabled
          text-field="content"
        />
      </template>
      <template v-if="isMultiple">
        <b-form-checkbox-group
          :options="answers"
          value-field="id"
          stacked
          class="ml-10"
          :id="`checkbox-group-${id}`"
          :name="`checkbox-group-${id}`"
          disabled
          text-field="content"
        />
      </template>
      <template v-if="isText">
        <basic-input
          placeholder="Nhập câu trả lời"
          name="name"
          disabled
          class="ml-10"
        />
      </template>
      <template v-if="isRating">
        <b-row v-for="(item, index) in answers" :key="index">
          <b-col>
            <div class="d-flex align-items-center ml-10">
              <b-button class="btn-rating mr-3">
                <span>{{ item.flag }}</span>
              </b-button>
              <span>{{ item.content }}</span>
            </div>
          </b-col>
        </b-row>
      </template>
    </b-col>
  </b-row>
</template>
<script>
import { QUESTION_TYPES } from '@/core/plugins/constants';
import sortby from 'lodash/sortBy';
export default {
  name: 'Question',
  props: {
    id: {
      type: String,
      default: null,
    },
    question: {
      type: Object,
      default: () => {},
    },
    showAction: {
      type: Boolean,
      default: true,
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: false,
      titleBegin: null,
      titleEnd: null,
      ratingLength: 0,
      rangeBegin: 0,
    };
  },
  computed: {
    isMultiple() {
      return this.question.type === QUESTION_TYPES.MULTIPLE;
    },
    isSingle() {
      return this.question.type === QUESTION_TYPES.SINGLE;
    },
    isText() {
      return this.question.type === QUESTION_TYPES.TEXT;
    },
    isRating() {
      return this.question.type === QUESTION_TYPES.RATING;
    },
    answers() {
      return sortby(this.question.answers, 'order');
    },
  },
  methods: {
    editQuestion() {
      this.$emit('edit', this.question);
    },
    deleteQuestion() {
      this.$emit('delete', this.question);
    },
    viewQuestion() {
      this.$emit('view', this.question);
    },
  },
};
</script>
<style lang="scss">
.answer-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #01645a !important;
  border-color: #01645a !important;
}
.answer-radio .custom-control .custom-control-label span {
  font-size: 1rem !important;
}
.dropdown-question {
  margin-left: 24px;

  .btn {
    padding: 0 !important;

    .svg-icon {
      svg {
        width: 24px;
        height: 20px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.btn-rating.active {
  background-color: #21a567 !important;
  border-color: #21a567 !important;
  color: #fff !important;
}

.btn-rating {
  background-color: #fff !important;
  border-color: #a3a3a3 !important;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  width: 50px;
  height: 50px;
}
</style>
